import React from 'react'
import HeaderAcceuil from '../shared/HeaderAcceuil'
import Footer from '../shared/Footer'
import { Outlet } from 'react-router-dom'
export default function AcceuilLayout() {
    return (
        <>
  
        <HeaderAcceuil/>
        <div  >
        <Outlet />
    
    
        </div>
        
      </>
      )
}
