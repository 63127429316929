import React, { useEffect, useState } from 'react'
import { getFederation } from '../Service/Federation/FederationApi';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

export default function Header() {
    const [federation , setfederation]=useState([]); 
    const navigate = useNavigate(); // Get the navigate function

    useEffect(() => {
        const fetchfederation = async()=>{
         const federationResp = await getFederation();
         if(federationResp.status === 200){
            setfederation(federationResp.data.data); 
          localStorage.setItem('saison',federationResp.data.data.saison)
         }else{
           alert("no federation found")
         }
         
         }  
         fetchfederation();  
       }, []);
  return (
    
    <header className='header'>
    
    <div className="info-container">
      <div className="arabic-info">
        <p>{federation.nomAr}</p>
        <p>{federation.adresseAr}</p>
        <p>الهاتف / الفاكس: {federation.telfax}</p>
        <p> البريد إلكتروني :{federation.email}</p>
      </div>
      <div className="image-container">
        <img src="/images/logo_FTG.png" alt="Image"  width="150" height="150" id="logo-image"  />
      </div>
      <div className="french-info">
        <p>{federation.nomFr}</p>
        <p>{federation.adresseFr}</p>
        <p>Tél./Fax : {federation.telfax}</p>
        <p>Email :{federation.email}</p>
      </div>
    </div>

  </header>
    
  )
}
