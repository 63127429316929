import React, { useState,useEffect } from 'react'
import { getAllEntraineurs } from '../../Service/Entraineur/EntraineurApi'
import {Getdiscipline} from '../../Service/Discipline/DisciplineApi'
import { getallclubs } from '../../Service/Federation/FederationApi'
export default function AllEntraineurs() {
  const [entraineurs, setEntraineurs] = useState([]);
  const [discipline, setDiscipline] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClub, setSelectedClub] = useState(''); // State for selected club
  const [clubs, setClubs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entraineurResp = await getAllEntraineurs();
        if (entraineurResp.status === 200) {
          const respdiscipline = await Getdiscipline();

          setDiscipline(respdiscipline.data.data);
          entraineurResp.data.data.forEach(element => {
            const specialiteListe = [];
            const categoriesAges = [];

            respdiscipline.data.data.forEach(item => {
              if (element[item.libellerFR] !== null) {
                specialiteListe.push(item['LibellerAR']);
                categoriesAges.push(element[`${[item.libellerFR]}_CA`]);
              }
            });
            element.specialites = specialiteListe.join(',');
            const categoriesAgeslist = categoriesAges.filter(item => item !== '' && item !== null);
            element.categories_age = categoriesAgeslist.join(',');
          });

          setEntraineurs(entraineurResp.data.data);
        }
      } catch (error) {
        console.error(error);
        alert('An error occurred while getting data');
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchClubs = async () => {
      try {
        const clubResp = await getallclubs();
        if (clubResp.status === 200) {
          setClubs(clubResp.data.data);
        }
      } catch (error) {
        console.error(error);
        alert('An error occurred while getting data');
      }
    };
    fetchClubs();
  }, []);

  const handleSpecialtyChange = (event) => {
    setSelectedSpecialty(event.target.value);
  };

  const handleClubChange = (event) => {
    setSelectedClub(event.target.value);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredEntraineurs = entraineurs
    .filter((trainer) => {
      if (selectedSpecialty && selectedSpecialty !== 'All') {
        return trainer.specialites.includes(selectedSpecialty);
      }
      return true;
    })
    .filter((trainer) => {
      const search = searchTerm.toLowerCase();
      return (
        trainer.nom.toLowerCase().includes(search) ||
        trainer.prenom.toLowerCase().includes(search)
      );
    })
    .filter((trainer) => {
      if (selectedClub && selectedClub !== 'All') {
        return trainer.nomClub === selectedClub;
      }
      return true;
    });

  return (
    <div>
          <div className='row mt-3 mr-2'><h2><strong> قائمة المدربين </strong></h2></div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
      
        <select
         style={{'width':'600px'}}
          id="specialtyFilter"
          onChange={handleSpecialtyChange}
          value={selectedSpecialty}
        >
          <option value="">تصفية حسب الاختصاصات</option>
          {discipline.map((item) => (
            <option key={item.id} value={item.LibellerAR}>
              {item.LibellerAR}
            </option>
          ))}
        </select>

        {/* Club filter dropdown */}
        <select
         style={{'width':'600px'}}
          id="clubFilter"
          onChange={handleClubChange}
          value={selectedClub}
        >
          <option value="">تصفية حسب النادي</option>
          {clubs.map((club) => (
            <option key={club.id} value={club.nomClub}>
              {club.nomClub}
            </option>
          ))}
        </select>
<div  style={{"marginTop":"20px" ,'width':'600px' , 'marginLeft':'50px'}}>
        <input
        placeholder='البحث بالاسم أو اسم العائلة'
          type="text"
          id="search"
        
          value={searchTerm}
          onChange={handleSearchTermChange}
        />
        </div>      </div>

      <table>
        <thead>
          <tr>
            <th className='center-text'>اسم</th>
            <th className='center-text'>اللقب</th>
            <th className='center-text'>جنسية</th>
            <th className='center-text'>اسم النادي</th>
            <th className='center-text'>الاختصاصات</th>
            <th className='center-text'>الفئات العمرية</th>
          </tr>
        </thead>
        <tbody>
          {filteredEntraineurs.map((item) => (
            <tr key={item.id}>
              <td>{item.nom}</td>
              <td>{item.prenom}</td>
              <td>{item.nationalite}</td>
              <td>{item.nomClub}</td>
              <td>{item.specialites}</td>
              <td>{item.categories_age}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
