import React, { useEffect, useState } from 'react';
import { getallclubs } from '../../Service/Federation/FederationApi';
import { useNavigate } from 'react-router-dom';
import { listeGouvernorat } from '../../utils/gouvernorat';
import { Getdiscipline } from '../../Service/Discipline/DisciplineApi';

export default function AllClubs() {
  const [clubs, setClubs] = useState([]);
  const [specialites, setSpecialites] = useState([]);
  const [filter, setFilter] = useState({
    name: '',
    address: '',
    specialties: '',
    governorate: '',
  });

  useEffect(() => {
    const fetchClubs = async () => {
      const clubResp = await getallclubs();
      if (clubResp.status === 200) {
        setClubs(clubResp.data.data);
      } else {
        alert('No club found');
      }
    };
    fetchClubs();
  }, []);

  useEffect(() => {
    const fetchSpecialties = async () => {
      const Resp = await Getdiscipline();
      if (Resp.status === 200) {
        setSpecialites(Resp.data.data);
      } else {
        alert('No specialties found');
      }
    };
    fetchSpecialties();
  }, []);

  const filteredClubs = clubs.filter((club) => {
    const { name, address, specialties, governorate } = filter;
    return (
      club.nomClub.toLowerCase().includes(name.toLowerCase()) &&
      club.adresse.toLowerCase().includes(address.toLowerCase()) &&
      (specialties === '' || club.specialite.toLowerCase().includes(specialties.toLowerCase())) &&
      (governorate === '' || club.gouvernorat.toLowerCase().includes(governorate.toLowerCase()))
    );
  });

  return (
    <div>
    <div className='row mt-3 mr-2'><h2><strong> قائمة الأندية </strong></h2></div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{"marginTop":"20px" ,'width':'400px' , 'marginLeft':'25px'}}>
        <input
          type="text"
          placeholder="Filter by Name"
          value={filter.name}
          onChange={(e) => setFilter({ ...filter, name: e.target.value })}
        />
        </div>
        <div style={{"marginTop":"20px" ,'width':'400px' ,'marginLeft':'25px' }}>
        <input
          type="text"
          placeholder="Filter by Address"
          value={filter.address}
          onChange={(e) => setFilter({ ...filter, address: e.target.value })}
        />
        </div>
        <select
        style={{'width':'600px'}}
          value={filter.specialties}
          onChange={(e) => setFilter({ ...filter, specialties: e.target.value })}
        >
          <option value="">تصفية حسب الاختصاصات</option>
          {specialites.map((specialite) => (
            <option key={specialite.id} value={specialite.LibellerAR}>
              {specialite.LibellerAR}
            </option>
          ))}
        </select>
        <select
        style={{'width':'600px'}}
          value={filter.governorate}
          onChange={(e) => setFilter({ ...filter, governorate: e.target.value })}
        >
            <option value="">تصفية حسب الولاية</option>
          {listeGouvernorat.map((governorate) => (
            <option key={governorate} value={governorate}>
              {governorate}
            </option>
          ))}
        </select>

      </div>
      <table>
        <thead>
          <tr>
            <th className='center-text'>اسم النادي</th>
            <th className='center-text'>الولاية</th>
            <th className='center-text'>العنوان</th>
            <th className='center-text'>رقم الهاتف</th>
            <th className='center-text'>البريد الإلكتروني</th>
            <th className='center-text'>الاختصاصات</th>
          </tr>
        </thead>
        <tbody>
          {filteredClubs.map((item) => (
            <tr key={item.numInscription}>
              <td>{item.nomClub}</td>
              <td>{item.gouvernorat}</td>
              <td>{item.adresse}</td>
              <td>{item.NmTelephone}</td>
              <td>{item.Email}</td>
              <td>{item.specialite}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
