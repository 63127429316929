import axios from "axios"


const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Authentification Web Service 
 * @returns 
 */

export async function getcountpdfs() {

    try {
        const idclub = localStorage.getItem('idunique');

        const response = await axios.get(`${url}uploadfile/verif/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  } 
  
  export async function getpdfs() {

    try {
        const idclub = localStorage.getItem('idunique');

        const response = await axios.get(`${url}uploadfile/getpdfs/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  } 
  export async function postpdfs(data) {

    try {
   
        const response = await axios.post(`${url}uploadfile/upload`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   

  export async function updatepdfs(data) {

    try {
   
        const response = await axios.put(`${url}uploadfile/update`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   

  export async function uploadPDF(namefile,data){
    try {
        axios.defaults.headers.common['X-File-Name'] = namefile;

        const response = await axios.post(`${url}uploadfile/uploadPDF`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }

  export async function uploadfederation(data){
    try {

        const response = await axios.post(`${url}uploadfile/uploadfederation`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }
