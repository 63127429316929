import React, { useState } from 'react'
import { useForm  } from "react-hook-form";
import { Login } from '../../Service/Login/LoginApi';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function FormulaireLogin() {
   
    const { register, handleSubmit,reset    } = useForm();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const response = await Login(data)
       if(response && response.status === 200){
        if(response.data.user.valide === -1 ){
                toast.warning('تم حظر حساب ')
        }else{
            localStorage.setItem('idunique', data.login) ;
            
            navigate(`/homeclub`); 
            window.location.reload()
        }
          

        }
        else{
            toast.error(response.data.message); 
        }
          };
  return (
    <div>
              <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

    <form  onSubmit={handleSubmit(onSubmit)} >
        
 
            <div className="form-group">
                 <label htmlFor="password"> كلمة المرور<span className="required"></span></label>
                 <input
                 type="text"
                 required
                 {...register("login")} 
                 />
             </div>
             <div className="form-group">
                 <label htmlFor="confirmPassword"> كلمة السر<span className="required"></span></label>
                 <input
                 type="password"
                 required
                 {...register("password")} 
                 
                 />
             </div>
           
             <button type='submit'>تسجيل الدخول</button>
             </form>
            
            </div>
  )
}
