import React ,{useEffect,useState} from 'react'
import { getFederation } from '../../Service/Federation/FederationApi';
import { useNavigate } from "react-router-dom";
import { getAllfiles } from '../../Service/Federation/FederationApi'


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetPubAcceuil } from '../../Service/Accueil/AccueilApi';
export default function Accueiltest() {
    const [federation , setfederation]=useState([]); 
    const [Publications , setPublications]=useState([]); 
    const [NewsBanner , setNewsBanner]=useState(); 

    const navigate = useNavigate();
    const [files , setfiles]=useState([]); 
    const { REACT_APP_API_URL } = process.env;
    const url = REACT_APP_API_URL
    const saison = localStorage.getItem('saison')

   useEffect(() => {
      const fetchfiles = async()=>{
      const fileResp = await getAllfiles();
      if(fileResp.status === 200){
        console.log(fileResp?.data.data)
        if(fileResp?.data.data && fileResp?.data.data.length !==0 ){
          const listClubsFile = fileResp?.data.data.filter((item) => item.accueil === 1);
  
          setfiles(listClubsFile);
        }
         
  
    
      }
      
      }  
      fetchfiles();  
  }, []);
  useEffect(() => {
    const fetchPublications = async()=>{
    const Resp = await GetPubAcceuil();
    if(Resp.status === 200){
        const listPublications = Resp.data.data;
       
        if( Resp.data.data.length !== 0){
          const banner_news =  Resp.data.data.find(item => item.type === "news_banner")
          const list_news = Resp.data.data.filter(item => item.type=== "newslist")
          setPublications(list_news);
          if(banner_news){
            setNewsBanner(banner_news)
          }
        }
  
    }else{
    }
    
    }  
    fetchPublications();  
}, []);
    useEffect(() => {
        const fetchfederation = async()=>{
         const federationResp = await getFederation();
         if(federationResp.status === 200){
          setfederation(federationResp.data.data)
         }
         
         }  
         fetchfederation();  

       }, []);
       const  formatDate=(dateString) =>{
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, options);
      }
      const downlod_file = (Nomfile) => {
        const FN = encodeURIComponent(Nomfile);
        const newTab = window.open(`${url}uploadfile/download/${FN}`, '_blank');
        if (newTab) {
          // If the new tab was successfully opened, you can focus on it (optional)
          newTab.focus();
        } else {
          toast.error('Popup blocker prevented opening a new tab.');
        } 
      };
      const openPDFInNewTab = (pdfFileName) => {
        const FN = encodeURIComponent(pdfFileName);
        const pdfUrl = `${url}uploadfile/downloadfederations/${FN}`;
      
        const newTab = window.open(pdfUrl, '_blank');
      
        if (!newTab) {
          toast.error('Popup blocker prevented opening a new tab.');
        }
      };
      
       const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true, // Enable automatic slide transitions
        autoplaySpeed: 500, // Adjust the speed of automatic transitions (in milliseconds)
        centerMode: true, // Center the dots
      };
   


  return (
    
 <div className="main-container yoga-template">
  {/*Banner section*/}
  <div className="banner" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL_UPLOADS}${NewsBanner?.image || 'images/banner.png'})`  }}>

    <div className="banner-overlay" />
   

 
  </div>
  <div className="logo-container-left">
 <a href="https://www.gymnastics.sport/site/"><img src="/images/logo fig.png" alt="Image" style={{  "width": "100px", "height": "100px", "border": "2px solid white" ,'borderRadius': '70%' }}/></a>
</div>   

  <div className="yogaPlace-section">
    <div className="row sections-detail">
      <div className="col-12">
        <p className="section-title-desc"> </p>
      </div>
    </div>
    <div className="inside-container ">
      <div className="row yogaPlace-col">
        <div className="col-lg-4 " onClick={() => { navigate('/AllClubs'); }}> 
          <div className="yogaPlace-img">
            <img src="images/lesclubs.png" className="yogaPlace-in-img" alt />
          </div>              
          <a href="#"><h3 className="yogaPlace-title">الجمعيات و النوادي</h3></a>
        </div>
        <div className="col-lg-4" onClick={() => { navigate('/AllEntraineurs'); }}>
          <div className="yogaPlace-img">
            <img src="images/entaineurs.jpg" className="yogaPlace-in-img" alt />
          </div>
          <a href="#"><h3 className="yogaPlace-title">المدربين</h3></a>
        </div>
        <div className="col-lg-4" onClick={() => { navigate('/AllAtheletes'); }}>
          <div className="yogaPlace-img">
            <img src="images/LeGymnastique.png" className="yogaPlace-in-img" alt />
          </div>
          <a href="#"><h3 className="yogaPlace-title">الرياضيين</h3></a>
        </div>
        
      </div>
    </div>
  </div>
  
  <section className="slider-container">
      <Slider {...settings}>
      <div className="slide">
          <img src="/images/banner1.png" alt="Image 1"  style={{'height':'700px'}}/>
          <div className="slide-content" style={{'backgroundColor':'white','marginTop':'450px'}} >
            <div className="slider_content slider_c_three color_white" >
              
            </div>
          </div>
        </div>
        <div className="slide">
          <img src="/images/1.png" alt="Image 1"  style={{'height':'700px'}}/>
          <div className="slide-content" style={{'backgroundColor':'white','marginTop':'450px'}} >
            <div className="slider_content slider_c_three color_white" >
              
            </div>
          </div>
        </div>
        <div className="slide">
          <img src="/images/2.png" alt="Image 2" style={{'height':'700px'}} />
          <div className="slide-content">
            
          </div>
        </div>
        <div className="slide">
          <img src="/images/3.png" alt="Image 3"style={{'height':'700px'}} />
          <div className="slide-content">
            <div className="slider_content slider_c_three color_white">
            
            </div>
          </div>
        </div>
        <div className="slide">
          <img src="/images/4.png" alt="Image 4"style={{'height':'700px'}} />
          <div className="slide-content">
            <div className="slider_content slider_c_three color_white">
            
            </div>
          </div>
        </div>
        <div className="slide">
          <img src="/images/5.png" alt="Image 3"style={{'height':'700px'}} />
          <div className="slide-content">
            <div className="slider_content slider_c_three color_white">
            
            </div>
          </div>
        </div>
      </Slider>
    </section>
  {/*Service*/}
  <div className="inside-container service-section">
   
    <div className="row service-detail">
      <div className="col-lg-6"  onClick={()=>{navigate('/home/loginadmin')}}>
      
        <a href="#"><h3 className="service-title"> فضاء الإدارة </h3></a>
      </div>
      <div className="col-lg-6"  onClick={()=>{navigate('/home')}}>
      
        <a href="#"><h3 className="service-title">  فضاء الأندية</h3></a>
      </div>
     
    </div>
  </div>
  {/*Class Yoga Center*/}
  {Publications?.map(item=>
  <div className="best-class-club mb-3 ml-4" key={item.id}>
  <div className="row best-class-sections-detail">
    <div className="col-lg-8 best-class-club-desc">
      <div className="row">
        <div className="col-lg-4">
        </div>
        <div className="col-lg-8 detail-col-club">
          <h1 className="club-section-title">{item.titre}</h1>
          <h2>{item.subtitre}</h2>
        
         
 <p>
  {item.description.split(':').map((line, index, array) => (
    <React.Fragment key={index}>
       {line.replace(':', '555')}

      
      {index !== array.length - 1 && <br />} {/* Add <br /> except for the last line */}
    </React.Fragment>
  ))}
</p>
{item.pdf !== '' ? (
<p className='mt-5'><a className='join-us-btn' onClick={()=>openPDFInNewTab(item.pdf.split('pdf/')[1])}>لمزيد من التفاصيل   </a></p>
):null}


        </div>
      </div>
    </div>
    <div className="col-lg-4 best-class-club-img" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL_UPLOADS}${item?.image || 'images/news.jpg'})`, width:"450px", height:"650px" }}>

    </div>
  </div>
</div>
  )}
  <div className="yogaPlace-section">
    <div className="row sections-detail">
      <div className="col-12">
       {files.length !== 0 ? (
        <h2 className="section-title">ملفات للتحميل</h2>
       ):null} 
      </div>
    </div>


    <div className="inside-container">
  {files.reduce((rows, item, index) => {
    if (index % 6 === 0) {
      rows.push([]);
    }
    rows[rows.length - 1].push(item);


    return rows;
    
  }, []).map((row, rowIndex) => (
    <div className="row" key={`row-${rowIndex}`}>
      {row.map((item, colIndex) => (
        <div className="col-lg-2" key={`col-${colIndex}`}>
          <div className="yogaPlace-img">
            <img src={item.imageUrl || "images/downloadicon.png"} className="yogaPlace-in-img"  alt="" />
          </div>
          <a href="#">
            <h3 className="yogaPlace-title" onClick={()=>openPDFInNewTab(item?.path?.split('pdf/')[1])}>{item.nomfichier || "ملف للتحميل"}</h3>
          </a>
          <p className="yogaPlace-desc">{item.description || ""}</p>
        </div>
      ))}
    </div>
  ))}

</div>

  
  </div>



 
  {/*Copyright*/}
  <footer class="footer">
        <div class="copyright">
          <div class="inside-container">
            <div class="row">
              <div class="col-md-3 order-2 order-md-12">
                <p> الجامعة التونسية للجمباز</p>
              </div>
              <div class="col-md-2 social order-1 order-md-12">                
                <a href="https://www.facebook.com/GYMTUN/?locale=fr_FR"><i className="fa fa-facebook fa-3x"></i></a>
             
              </div>
            </div>
          </div>
        </div>
      </footer>
</div>

  )
}
