import React ,{useState,useEffect}from 'react'
import { Nav, Tab, Row, Col, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { createuser, getroles } from '../../Service/Federation/FederationApi';
import { toast, ToastContainer } from 'react-toastify';
import { useForm  } from "react-hook-form";
import 'bootstrap/dist/css/bootstrap.min.css';


export default function AjoutResponsable({ showModal  ,setshowModal }) {
    const [roles, setroles] = useState([]);
    const navigate = useNavigate();

    const { register, handleSubmit, reset } = useForm();
    useEffect(() => {
        const fetchRoles = async () => {
            const Resp = await getroles();
            if (Resp.status === 200) {

                setroles(Resp.data.data);
            } else {
                alert("no role found")
            }

        }
        fetchRoles();
    }, []);

    const onSubmit = async (data) => {
        const response = await createuser(data)
       if(response && response.status === 201){
        toast.success('تم تسجيل المدربين بنجاح  ')
        data ={}
        setshowModal(false)
        window.location.reload()
    }else{
        toast.error('حدث خطأ أثناء حفظ البيانات');

    }
          };
    return (
        <div>
  <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

            <Modal show={showModal} onHide={()=>setshowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>إضافة مستخدم</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)} >


                        <div className="form-group">
                            <label htmlFor="prenom">  الاسم<span className="required"></span></label>
                            <input
                                type="text"
                                required
                                {...register("prenom")}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="nom">  اللقب <span className="required"></span></label>
                            <input
                                type="text"
                                required
                                {...register("nom")}

                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">  بريد إلكتروني <span className="required"></span></label>
                            <input
                                type="email"
                                required
                                {...register("email")}

                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">   كلمة السر <span className="required"></span></label>
                            <input
                                type="password"
                                required
                                {...register("password")}

                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="id">  cin<span className="required"></span></label>
                            <input
                                type="text"
                                required
                                {...register("id")}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="role">  المنصب<span className="required"></span></label>
                            <select
                                {...register("role")}
                            >
                                {roles.map((item, index) => (
                                    <option key={index} value={item.id}>
                                        {item.libeller}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button type='submit'> حفظ</button>
                    </form>            
                    </Modal.Body>
            </Modal>
        </div>
    )
}
