import React, { useState } from 'react'
import { Nav, Tab, Row, Col, Modal } from 'react-bootstrap';


export default function Athletefiche({ showModal,setshowModal  ,item}) {
    const [key, setKey] = useState('home');
    const handleClose=()=>{
        setshowModal(false)
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{key === 'home' ? 'هوية' : key === 'profile' ? 'نتائج ' : ' الإعلام'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="main-container yoga-template">

                    <div >
                        <div className="col-lg-4 best-class-club-img" >
                        <img src={`${process.env.REACT_APP_API_URL_UPLOADS}${item?.image || 'images/palestine1.jpg'}`} alt="Image" />
                        </div></div>
                    <Tab.Container id="my-tabs" activeKey={key} onSelect={(k) => setKey(k)}>
                        <Row>
                            <Col>
                                <Nav fill variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="home">هوية</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="profile">نتائج</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="contact">الإعلام</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="home">
                                        <div className='row mr-5'>الاسم : {item?.nom}</div>
                                        <div className='row mr-5'>اللقب : {item?.prénom}</div>
                                        <div className='row mr-5'>الجنسية : {item?.nationalite}</div>
                                        <div className='row mr-5'> تاريخ الميلاد : {item?.datenaissance}</div>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="profile">
                                        <h1>Profile Content</h1>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="contact">
                                        <h1>Contact Content</h1>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </Modal.Body>
        </Modal>
    );


}
