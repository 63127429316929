import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { uploadfederation } from '../../Service/ImportPdfs/ImportPdfsApi';
import ClubsTables from '../Clubs/ClubsTables';

import FedrationFile from '../FedrationFile/FedrationFile';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function SecretariatGeneral() {
  const [resp, setresp] = useState([]);
  const [filename, setfilname] = useState(null);
  const [descriptions, setdescriptions] = useState(null);
  const [ClubsChecked, setClubsChecked] = useState(false);
  const [AcceuilChecked, setAcceuilChecked] = useState(false);

  let { data } = useParams();


  useEffect(() => {
    // Parse and update the resp state when data changes
    setresp(JSON.parse(decodeURIComponent(data)));
  }, [data]);
  const [isEditing, setIsEditing] = useState(false);
  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };
  const handleChange = (e) => {

    const { name, value } = e.target;
    const updatedResp = { ...resp };
    if (name === 'logo') {
      const file = e.target.files[0];
      updatedResp[name] = file
    } else {
      updatedResp[name] = value;
    }
    setresp(updatedResp);

  };
  const handleEditClick = async () => {


  }
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const inputFilename = '_Federation' + filename + localStorage.getItem('saison'); // Get the filename from the input field

    if (selectedFile && inputFilename) {
      const formData = new FormData();
      formData.append('pdf', selectedFile);

      // Encode the inputFilename if needed
      const nomfichier = encodeURIComponent(inputFilename);
      const Descriptions = encodeURIComponent(descriptions);
      if (ClubsChecked) {
        axios.defaults.headers.common['x-club'] = 1;

      } if (AcceuilChecked) {
        axios.defaults.headers.common['x-acceuil'] = 1;

      }
      axios.defaults.headers.common['nomfichier'] = filename;
      axios.defaults.headers.common['x-file-name'] = nomfichier;
      axios.defaults.headers.common['resp'] = resp.email;
      axios.defaults.headers.common['event'] = "upload";
      axios.defaults.headers.common['x-descriptions'] = Descriptions;

      uploadfederation(formData)
        .then((response) => {
          toast.success("تحميل الملف بنجاح");
        })
        .catch((error) => {
          toast.error("نحن آسفون، يبدو أن هناك مشكلة")
        })
        .finally(() => {
          // Remove custom headers after sending the request
          delete axios.defaults.headers.common['x-file-name'];
          delete axios.defaults.headers.common['resp'];
          delete axios.defaults.headers.common['event'];

          window.location.reload();
        });
    } else {
      toast.error("   أدخل أولاً اسم الملف");
    }
  };


  return (


    <div className="main-container yoga-template">
      {/*Banner section*/}
      <div className="banner" style={{ backgroundImage: `url('/images/photo1.jpg'})`, maxHeight: '200px' }}>

        <form >



          {isEditing ? (
            <div>
              إسم
              <input
                type="text"
                name="nomClub"
                value={resp.nom}
                onChange={(e) => handleChange(e)}
                style={{ width: '500px' }}
              />

            </div>) : <p><strong>إسم   </strong> :  &nbsp;&nbsp; &nbsp;{resp.nom}</p>}
          {isEditing ? (
            <div>
              اللقب
              <input
                type="text"
                name="adresse"
                value={resp.prenom}
                onChange={(e) => handleChange(e)}
                style={{ width: '500px' }}
              />
            </div>
          ) : <p><strong>اللقب</strong> :  &nbsp;&nbsp; &nbsp;{resp.prenom}</p>}



          {isEditing ? (<div><button className="submit-btn" onClick={handleEditClick}> حفظ</button></div>) : null}



        </form>


      </div>

      <div className="best-class-club mb-3 ml-4 " >
  <div className="row best-class-sections-detail">
    <div className="col-lg-8 best-class-club-desc">
      <div >
      < FedrationFile />


      </div>
    </div>
    <div className="col-lg-4 best-class-club-img" >
    <div >

<div className='mt-5'>
<input type='text' placeholder=" اسم الملف" onChange={(e)=>{setfilname(e.target.value)}}/>

<input type='text' placeholder="تعليق" onChange={(e) => { setdescriptions(e.target.value) }} />
</div>
<div>
<label>
  <input type='checkbox' value='acceuil' onChange={(e) => { setAcceuilChecked(e.target.checked) }} />
  الصفحة الرئيسية
</label>
</div>
<div>
<label>
  <input type='checkbox' value='clubs' onChange={(e) => { setClubsChecked(e.target.checked) }} />
  الأندية
</label>
</div>

<label for={filename} class="custom-file-input">
<span class="folder-icon">&#128193;</span> 
</label>
<input type="file"  id={filename} accept=".pdf" onChange={handleFileChange} style={{"display": "none"}} />


</div>
    </div>
  </div>
</div>
      <div className="yogaPlace-section">
        <ClubsTables />
      </div>
     
      
    </div>
  )
}
/*          {!isEditing ? (<button className="submit-btn" onClick={toggleEditing}> تحديث</button>) : null}
*/