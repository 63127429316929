import React from 'react'
import Header from '../shared/Header'
import Footer from '../shared/Footer'
import { Outlet } from 'react-router-dom'
import SubHeaderFederation from '../shared/SubHeaderFederation'
export default function FederationLayout() {
    return (
        <>
        <SubHeaderFederation/>
        <Header/>
        <main  className='background'>
          
          <Outlet />
          
        </main>
       
      </>
      )
}
