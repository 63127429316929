import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { postpdfs ,updatepdfs} from '../../Service/ImportPdfs/ImportPdfsApi';
import io from "socket.io-client";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Uploadfichier({ idinput,nomfichier, type,  id ,isUpdatingfile}) {
  const { REACT_APP_API_URL } = process.env;
  const url = REACT_APP_API_URL;
  const [file ,setfile]=useState([])
  const UserSocket = useRef(null); // Use useRef to store the socket reference

  useEffect(() => {
    UserSocket.current = io(url); // Create and store the socket instance

    return () => {
      if (UserSocket.current) {
        UserSocket.current.disconnect(); // Disconnect the socket when the component unmounts
      }
    };
  }, [url]);

  const handleFileChange = () => {
 
    const selectedFile = file;
    if(!isUpdatingfile){
      uploadFile(selectedFile)

    }else{
      update(selectedFile)
    }
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    
    formData.append('pdf', file);

    let fileName;
    if (nomfichier) {
      fileName = encodeURIComponent(nomfichier);
    } else {
      const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
      fileName = encodeURIComponent(fileNameWithoutExtension + '_' + id + 'directions');
    }

    const idclub = encodeURIComponent(id);
    const fileType = encodeURIComponent(type);

    axios.defaults.headers.common['X-File-Name'] = fileName;
    axios.defaults.headers.common['idClub'] = idclub;
    axios.defaults.headers.common['type'] = fileType;

    postpdfs(formData)
    .then((response) => {
      const data = {
        sender: id, 
        event: `update`,
        description:` ${localStorage.getItem('clubName')} حدث ${type}  ` ,
        date : new Date()
     }
      UserSocket.current.emit("message", data);

    })
      .catch((error) => {
        toast.error("حدث خطأ أثناء تنزيل الملف");
      })
      .finally(() => {
        // Supprimez l'en-tête personnalisé après l'envoi de la requête
        delete axios.defaults.headers.common['X-File-Name'];
        delete axios.defaults.headers.common['idClub'];
        delete axios.defaults.headers.common['type'];

       // window.location.reload();
      });
  }


  const update = (file) => {
    const formData = new FormData();
    formData.append('pdf', file);
    let fileName ; 
    if(nomfichier){
      fileName = encodeURIComponent(nomfichier);
    }
    else{
      const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
       fileName = encodeURIComponent(fileNameWithoutExtension+'_'+ id+"directions");

    }
    const idclub = encodeURIComponent(id);
    const fileType = encodeURIComponent(type);

    axios.defaults.headers.common['X-File-Name'] = fileName;
    axios.defaults.headers.common['idClub'] = idclub;
    axios.defaults.headers.common['type'] = fileType;

    updatepdfs(formData)
      .then((response) => {
        const data = {
          sender: id, 
          event: `update`,
          description:` ${localStorage.getItem('clubName')} حدث ${type}  ` ,
          date : new Date()
       }
        UserSocket.current.emit("message", data);

      })
      .catch((error) => {
        console.error('Erreur lors du téléchargement du fichier', error);
      })
      .finally(() => {
        // Supprimez l'en-tête personnalisé après l'envoi de la requête
        delete axios.defaults.headers.common['X-File-Name'];
        delete axios.defaults.headers.common['idClub'];
        delete axios.defaults.headers.common['type'];

        window.location.reload();
      });
  };

  return (
    <div >
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />


<input type="file"  id={idinput} accept=".pdf" onChange={(e)=>setfile(e.target.files[0])}  />
     <button onClick={handleFileChange}  >{isUpdatingfile ? 'تعديل' : 'حفظ'}</button>
    </div>
  );
}
