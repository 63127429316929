import React, { useEffect,useRef,useState } from 'react';
import { getcountpdfs ,getpdfs} from '../../Service/ImportPdfs/ImportPdfsApi';
import { getcountdirection } from '../../Service/Directionclub/DirectionclubApi';
import Uploadfichier from '../uploadficher/uploadfichier';
import { getclub  } from '../../Service/Inscription/ClubInscription/clubinscription';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import io from "socket.io-client";
import { getAllfiles } from '../../Service/Federation/FederationApi'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ImportPdf() {
    const [countdirection, setCountDirection] = useState(0);
    const [pdfsinfo , setpdfsinfo]= useState([]);
    const { REACT_APP_API_URL } = process.env;
    const url = REACT_APP_API_URL
    const id = localStorage.getItem('idunique');
    const [files , setfiles]=useState([]); 
    const saison = localStorage.getItem('saison')

    const [club , setclub]=useState([]); 

    const [verif, setVerif] = useState({
        0: true,
        1: true,
        2: true,
        3: true,
        4:true,
    });

    useEffect(() => {
        const fetchClubs = async()=>{
        const clubResp = await getclub();
        if(clubResp.status === 200){
            setclub(clubResp.data.data[0]);
           localStorage.setItem('clubName',clubResp.data.data[0].nomClub)
  
      
        }else{
        alert("no federation found")
        }
        
        }  
        fetchClubs();  
    }, []);
    useEffect(() => {
        const fetchpdfs = async()=>{
        const PdfsResp = await getpdfs();
        if(PdfsResp.status === 200){
            setpdfsinfo(PdfsResp.data.data);
  
      
        }else{
        alert("no pdfs found")
        }
        
        }  
        fetchpdfs();  
    }, []);
   /* const UserSocket = io(url);

    useEffect(() => {
        UserSocket.on("connect", () => {
            UserSocket.emit("message", "i am user");
        });
        
        UserSocket.on("adminResponse", (data) => {
          console.log("Received response on the user side:", data);
        });
    }, []);
   */
    useEffect(() => {
        const fetchCountDirection = async () => {
            const countResp = await getcountdirection();

            if (countResp.status === 200) {
                setCountDirection(countResp.data.data[0].NbD);
            } else {
                alert("no direction found");
            }
        };

        fetchCountDirection();
    }, []);
   
    
    useEffect(() => {
        const countpdf = async () => {
            const clubResp = await getcountpdfs();

            if (clubResp.status === 200) {
                const updatedVerif = { ...verif }; // Create a copy of verif

                clubResp.data.data.forEach(element => {
                    switch (element.type) {
                        case "مطلب_إنخراط":
                            updatedVerif[0] = false;
                            break;
                        case "1معاليم_انخراط":
                            updatedVerif[1] = false;
                            break;
                        case "معاليم_انخراط":
                                updatedVerif[2] = false;
                                break;
                        case "مسير":
                           
                            if (element.count === countdirection) {
                                updatedVerif[3] = false;
                            }
                            break;
                        case "كراسشروط":
                            updatedVerif[4] = false;

                            break;
                        default:
                            break;
                    }
                });

                setVerif(updatedVerif); // Update verif state with the modified values
            } else {
                alert("no club found");
            }
        };

        countpdf();
    }, [countdirection, verif]);
    const downlodfile = (Nomfile) => {
        const FN = encodeURIComponent(Nomfile);
        //alert(FN);
        const newTab = window.open(`${url}uploadfile/download/${FN}`, '_blank');
        if (newTab) {
          // If the new tab was successfully opened, you can focus on it (optional)
          newTab.focus();
        } else {
          alert('Popup blocker prevented opening a new tab.');
        }
      };
/*******************upload */

const UserSocket = useRef(null); // Use useRef to store the socket reference

useEffect(() => {
  UserSocket.current = io(url); // Create and store the socket instance

  return () => {
    if (UserSocket.current) {
      UserSocket.current.disconnect(); // Disconnect the socket when the component unmounts
    }
  };
}, [url]);
useEffect(() => {
    const fetchfiles = async()=>{
    const fileResp = await getAllfiles();
    if(fileResp.status === 200){
        const listClubsFile = fileResp.data.data.filter((item) => item.clubs === 1);

            setfiles(listClubsFile);

  
    }else{
    alert("no club found")
    }
    
    }  
    fetchfiles();  
}, []);
const  formatDate=(dateString) =>{
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }
    const downlod_file = (Nomfile) => {
    const FN = encodeURIComponent(Nomfile);
    //alert(FN);
    const newTab = window.open(`${url}uploadfile/download/${FN}`, '_blank');
    if (newTab) {
      // If the new tab was successfully opened, you can focus on it (optional)
      newTab.focus();
    } else {
      alert('Popup blocker prevented opening a new tab.');
    } 
  };
  const openPDFInNewTab = (pdfFileName) => {
    const FN = encodeURIComponent(pdfFileName);
    const pdfUrl = `${url}uploadfile/downloadfederations/${FN}`;
  
    const newTab = window.open(pdfUrl, '_blank');
  
    if (!newTab) {
      alert('Popup blocker prevented opening a new tab.');
    }
  };
  
  
    return (

        <div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

        <div> 
          <div className="containerdiv">
          <div className="right-panel">  
          <div className="pdf-container" style={{'backgroundColor':'white'}}>
  <h2>الملفات للتحميل</h2>
  {files.map((pdf) => (
    <div className="pdf-item" key={pdf.id}>
      <p className="file-info">
        <span className="file-name">
          {pdf.path.split('Federation')[1].split(saison)[0]}&nbsp;&nbsp;
        </span>
        <span className="upload-date">
          تاريخ الرفع :&nbsp;&nbsp; {formatDate(pdf.date)}&nbsp;&nbsp;
        </span>
        <span className="description">
          الوصف:&nbsp;&nbsp;{pdf.descriptions}
        </span>
      </p>
      <div className="buttons">
        <button onClick={() => openPDFInNewTab(pdf.path.split('pdf/')[1])}>
          معاينة
        </button>
        <button onClick={() => downlod_file(pdf.path.split('pdf/')[1])}>
          تحميل
        </button>
      </div>
    </div>
  ))}
</div>

     </div>
          <hr className="divider" />
          <div className="left-panel">
  
          <div  className="file-upload-row">
        
        {verif[0] ? (
            <p>
              <p >  مطلب إنخراط  </p> &nbsp;&nbsp; &nbsp;&nbsp;
              <Uploadfichier idinput={"Demandeinscrip"} nomfichier={id + "Demandeinscrip"} type={"مطلب_إنخراط"} id={id} isUpdatingfile={false}  />

            </p>
        ) : (
            <div>
                
            <p>
            <CheckCircleIcon style={{ color: 'green' }} />  مطلب إنخراط    <a onClick={()=>{downlodfile(id + "Demandeinscrip")}} >    <i className="fa fa-download" aria-hidden="true"></i></a></p>
            {pdfsinfo.map((pdf) =>
    pdf.type === "مطلب_إنخراط" ? (
      <span key={pdf.id}>
        &nbsp; ( {formatDate(pdf.DateCreation)})
      </span>
    ) : null
  )}
            <p>إعادة تحميل &nbsp;&nbsp;   <Uploadfichier  idinput={"Demandeinscrip"} nomfichier={id + "Demandeinscrip"} type={"مطلب_إنخراط"} uploadFile  id={id}  isUpdatingfile={true}/></p>

            </div>
        )}
        {verif[1] ? (
         
         <p>
               رسوم التسجيل &nbsp;&nbsp; &nbsp;&nbsp;
               <Uploadfichier  idinput={"Fraisinscri1"} nomfichier={id + "Fraisinscri1"} type={"1معاليم_انخراط"} id={id} isUpdatingfile={false} />

 
         </p>
     ) : (
         <div>
         <p>
         <CheckCircleIcon style={{ color: 'green' }} />  رسوم التسجيل    <a onClick={()=>{downlodfile(id + "Fraisinscri1")}} >    <i className="fa fa-download" aria-hidden="true"></i></a></p>
         {pdfsinfo.map((pdf) =>
    pdf.type === "1معاليم_انخراط" ? (
      <span key={pdf.id}>
        &nbsp; (   {formatDate(pdf.DateCreation)})
      </span>
    ) : null
  )}
         <p>إعادة تحميل &nbsp;&nbsp;   
         <Uploadfichier   idinput={"Fraisinscri1"}  nomfichier={id + "Fraisinscri1"} type={"1معاليم_انخراط"} id={id} isUpdatingfile={true} />
 
        </p>
         </div>
     )}
     
       {club.valide === 1 ?(
        <div>
            {verif[2] ? (
         
            <p>
                معاليم إنخراط والاجازات &nbsp;&nbsp; &nbsp;&nbsp;
                <Uploadfichier  idinput={"Fraisinscri"}  nomfichier={id + "Fraisinscri"} type={"معاليم_انخراط"} id={id} isUpdatingfile={false}  />
            </p>
        ) : (
            <div>
            <p>
            <CheckCircleIcon style={{ color: 'green' }} />  معاليم نخراط والاجازات   <a onClick={()=>{downlodfile(id + "Fraisinscri")}} >    <i className="fa fa-download" aria-hidden="true"></i></a></p>
            {pdfsinfo.map((pdf) =>
                    pdf.type === "معاليم_انخراط" ? (
                    <span key={pdf.id}>
                        &nbsp; (   {formatDate(pdf.DateCreation)})
                    </span>
                    ) : null
                )}
            <p>إعادة تحميل &nbsp;&nbsp;   <Uploadfichier  idinput={"Fraisinscri"}  nomfichier={id + "Fraisinscri"} type={"مطلب_إنخراط"}  id={id}  isUpdatingfile={true} /></p>

            </div>
        )}
        {verif[3] ? (
            <p>
                
                بطاقة إرشادات مسير &nbsp;&nbsp; &nbsp;&nbsp;
                <span style={{'color': "red"}}>يجب أن يكون اسم الملف الاسم_اللقب </span> &nbsp;&nbsp; <Uploadfichier idinput={"direction"}  type={"مسير"} id={id} isUpdatingfile={false}  />
            </p>
        ) : (
            <div>
            <p>
               <CheckCircleIcon style={{ color: 'green' }} /> بطاقة إرشادات مسير <a onClick={()=>{downlodfile(id + "directions")}} >    <i className="fa fa-download" aria-hidden="true"></i></a></p>
             <p>إعادة تحميل &nbsp;&nbsp;   <Uploadfichier  idinput={"direction"}  type={"مسير"}   id={id}  isUpdatingfile={true} /></p>
            </div>
        )}
        {verif[4] ? (
            <p>
                كراس شروط المنصة الرقمية &nbsp;&nbsp; &nbsp;&nbsp;
                <Uploadfichier idinput={"pdfcahierdecharge"}  nomfichier={id + "pdfcahierdecharge"} type={"كراسشروط"} id={id}  />
            </p>
        ) : (
            <div>
            <p>
                <CheckCircleIcon style={{ color: 'green' }} /> كراس شروط المنصة الرقمية  <a onClick={()=>{downlodfile(id + "pdfcahierdecharge")}} >    <i className="fa fa-download" aria-hidden="true"></i></a></p>
            <p>إعادة تحميل &nbsp;&nbsp;   <Uploadfichier idinput={"pdfcahierdecharge"}  nomfichier={id + "pdfcahierdecharge"} type={"مطلب_إنخراط"}  id={id}  isUpdatingfile={true} /></p>

            </div>
        )}
        </div>
            
        ):null} 

    </div>
  
             
          </div>
         
        </div>
          </div>
        
           </div>



       
  )
}
/**  {club.valide === 1 ?(
            <div>{verif[2] ? (
             
                <p>
                    معاليم إنخراط والاجازات &nbsp;&nbsp; &nbsp;&nbsp;
                    <Uploadfichier nomfichier={id + "Fraisinscri"} type={"معاليم_انخراط"} id={id} isUpdatingfile={false}  />
                </p>
            ) : (
                <div>
                <p>
                <CheckCircleIcon style={{ color: 'green' }} />  معاليم نخراط والاجازات   <a onClick={()=>{downlodfile(id + "Fraisinscri")}} >    <i className="fa fa-download" aria-hidden="true"></i></a></p>
                
                <p>إعادة تحميل &nbsp;&nbsp;   <Uploadfichier nomfichier={id + "Fraisinscri"} type={"مطلب_إنخراط"}  id={id}  isUpdatingfile={true} /></p>

                </div>
            )}
            {verif[3] ? (
                <p>
                    
                    بطاقة إرشادات مسير &nbsp;&nbsp; &nbsp;&nbsp;
                    <span style={{'color': "red"}}>يجب أن يكون اسم الملف الاسم_اللقب </span> &nbsp;&nbsp; <Uploadfichier type={"مسير"} id={id} isUpdatingfile={false}  />
                </p>
            ) : (
                <div>
                <p>
                   <CheckCircleIcon style={{ color: 'green' }} /> بطاقة إرشادات مسير <a onClick={()=>{downlodfile(id + "directions")}} >    <i className="fa fa-download" aria-hidden="true"></i></a></p>
                 <p>إعادة تحميل &nbsp;&nbsp;   <Uploadfichier type={"مسير"}   id={id}  isUpdatingfile={true} /></p>
                </div>
            )}
            {verif[4] ? (
                <p>
                    كراس شروط المنصة الرقمية &nbsp;&nbsp; &nbsp;&nbsp;
                    <Uploadfichier nomfichier={id + "pdfcahierdecharge"} type={"كراسشروط"} id={id}  />
                </p>
            ) : (
                <div>
                <p>
                    <CheckCircleIcon style={{ color: 'green' }} /> كراس شروط المنصة الرقمية  <a onClick={()=>{downlodfile(id + "pdfcahierdecharge")}} >    <i className="fa fa-download" aria-hidden="true"></i></a></p>
                <p>إعادة تحميل &nbsp;&nbsp;   <Uploadfichier nomfichier={id + "pdfcahierdecharge"} type={"مطلب_إنخراط"}  id={id}  isUpdatingfile={true} /></p>

                </div>
            )}
            </div>
                
            ):null} */



/*

{verif[1] ? (
             
                <p>
                    معاليم إنخراط والاجازات &nbsp;&nbsp; &nbsp;&nbsp;
                    <Uploadfichier nomfichier={id + "Fraisinscri"} type={"معاليم_انخراط"} id={id} isUpdatingfile={false}  />
                </p>
            ) : (
                <div>
                <p>
                <CheckCircleIcon style={{ color: 'green' }} />  معاليم نخراط والاجازات   <a onClick={()=>{downlodfile(id + "Fraisinscri")}} >    <i className="fa fa-download" aria-hidden="true"></i></a></p>
                
                <p>إعادة تحميل &nbsp;&nbsp;   <Uploadfichier nomfichier={id + "Fraisinscri"} type={"مطلب_إنخراط"}  id={id}  isUpdatingfile={true} /></p>

                </div>
            )}
            {verif[2] ? (
                <p>
                    
                    بطاقة إرشادات مسير &nbsp;&nbsp; &nbsp;&nbsp;
                    <span style={{'color': "red"}}>يجب أن يكون اسم الملف الاسم_اللقب </span> &nbsp;&nbsp; <Uploadfichier type={"مسير"} id={id} isUpdatingfile={false}  />
                </p>
            ) : (
                <div>
                <p>
                   <CheckCircleIcon style={{ color: 'green' }} /> بطاقة إرشادات مسير <a onClick={()=>{downlodfile(id + "directions")}} >    <i className="fa fa-download" aria-hidden="true"></i></a></p>
                 <p>إعادة تحميل &nbsp;&nbsp;   <Uploadfichier type={"مسير"}   id={id}  isUpdatingfile={true} /></p>
                </div>
            )}
            {verif[3] ? (
                <p>
                    كراس شروط المنصة الرقمية &nbsp;&nbsp; &nbsp;&nbsp;
                    <Uploadfichier nomfichier={id + "pdfcahierdecharge"} type={"كراسشروط"} id={id}  />
                </p>
            ) : (
                <div>
                <p>
                    <CheckCircleIcon style={{ color: 'green' }} /> كراس شروط المنصة الرقمية  <a onClick={()=>{downlodfile(id + "pdfcahierdecharge")}} >    <i className="fa fa-download" aria-hidden="true"></i></a></p>
                <p>إعادة تحميل &nbsp;&nbsp;   <Uploadfichier nomfichier={id + "pdfcahierdecharge"} type={"مطلب_إنخراط"}  id={id}  isUpdatingfile={true} /></p>

                </div>
            )}
            */

