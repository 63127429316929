
import axios from "axios"


const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Authentification Web Service 
 * @returns 
 */

export async function Login(data) {

    try {
        console.log(data);
        const response = await axios.post(`${url}Club/login`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   
