import React, { useEffect, useState } from 'react'
import { getFederation } from '../Service/Federation/FederationApi';
export default function PdfFooter() {
    const [federation , setfederation]=useState([]); 

    useEffect(() => {
        const fetchfederation = async()=>{
         const federationResp = await getFederation();
         if(federationResp.status === 200){
            setfederation(federationResp.data.data); 
          localStorage.setItem('saison',federationResp.data.data.saison)
         }else{
           alert("no federation found")
         }
         
         }  
         fetchfederation();  
       }, []);
  return (
    <footer  className="footerStyle">

    <p>&#127968; {federation.adresseAr}</p>
    <p>   &#9742;الهاتف / الفاكس:  {federation.telfax}</p>
    <p>&#9993; البريدإلكتروني: {federation.email}</p>


</footer>
  )
}
