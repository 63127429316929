import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom';

export default function SubHeader() {

  const currentPath = window.location.pathname; 

    const navigate = useNavigate(); 
    const logout =()=>{
        localStorage.removeItem('idunique');
        localStorage.removeItem('saison');
        navigate('/home');
        window.location.reload();
  
  
      }
    const goBack = () => {
      navigate(-1); // Use navigate with -1 to go back one step
  };
  return (
   
<div className='subheader'>
<div className="logo-container">
  <img src="/images/logo fig.png" alt="Image" style={{  "width": "100px", "height": "100px", "border": "2px solid white" ,'borderRadius': '70%' }}/>
</div>
  {localStorage.getItem("idunique") ? (
    <ul>
    <li> <button className="home" onClick={logout}>
        &#128274; تسجيل خروج {/* Unicode for a logout icon */}
      </button></li> 
      <li> {currentPath !== "/homeclub" ? (
     
        <button className="home" onClick={() => navigate('/homeclub')}>
          &#127968; الصفحة الرئيسية الخاصة بك {/* Unicode for a home icon */}
        </button>
      ) : null}</li>
    </ul>
  ) :   <ul>
  {window.location.pathname !== '/home/loginadmin'?(
 <li> <button className="home" onClick={()=>{navigate('/home/loginadmin')}}>
 ➡️ فضاء الإداري{/* Unicode for a logout icon */}
   </button></li> 
  ):null}
   <li> <button className="home" onClick={()=>{navigate('/')}}>
 ➡️  الصفحة الرئيسية{/* Unicode for a logout icon */}
   </button></li> 
   
 </ul>}


   
</div>

    
  )
}
