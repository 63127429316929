import React ,{useEffect,useState}from 'react'
import { getAllfiles } from '../../Service/Federation/FederationApi'
import { useNavigate } from "react-router-dom";
export default function FedrationFile() {

    const navigate = useNavigate();
     const saison = localStorage.getItem('saison')
    const { REACT_APP_API_URL } = process.env;
    const url = REACT_APP_API_URL
    const [files , setfiles]=useState([]); 
    useEffect(() => {
      const fetchfiles = async()=>{
      const fileResp = await getAllfiles();
      if(fileResp.status === 200){
        setfiles(fileResp.data.data);
  
    
      }else{
      alert("no club found")
      }
      
      }  
      fetchfiles();  
  }, []);
 
  const openPDFInNewTab = (pdfFileName) => {
    const FN = encodeURIComponent(pdfFileName);
    const pdfUrl = `${url}uploadfile/downloadfederations/${FN}`;
  
    const newTab = window.open(pdfUrl, '_blank');
  
    if (!newTab) {
      alert('Popup blocker prevented opening a new tab.');
    }
  };
  
  return (
    <div >
         
         {files ? (  
    <div>     
    <header>
<div className="image-container">

<div className="info-container">

<p><strong >قائمة  ملفات     </strong></p>

<p><strong> الموسم الرياضي {localStorage.getItem('saison')}</strong></p>
</div>

</div>

</header>
<div style={{ 'height':'500px' ,'overflowY': 'auto'}}>

 <table>
   <thead>
     <tr>
       <th className='center-text'>ملف</th>
     
       
        <th className='center-text'>تاريخ</th>
     </tr>
   </thead>
   <tbody>
     {files.map((pdf) => (
       <tr key={pdf.id} >
            <td>{pdf.nomfichier}</td>

          
            <td className='center-text'>   {new Date(pdf.date).getFullYear()}-{(new Date(pdf.date).getMonth() + 1)
                        .toString()
                        .padStart(2, '0')}-{new Date(pdf.date).getDate().toString().padStart(2, '0')}</td>
             <td className='center-text' ><a onClick={()=>{openPDFInNewTab(pdf?.path?.split('pdf/')[1])}}> &#128065;</a></td>
          <td className='center-text'>{pdf.clubs !== 0 ? (<p>الأندية</p>):null} {'  '}{pdf.accueil !== 0 ? (<p>   الصفحة الرئيسية</p>):null} </td>

       </tr>
     ))}
   </tbody>
 </table>   
 </div>  </div>
         ):null}
 
   </div>
  )
}
