import React from 'react'
import Header from '../shared/Header'
import Footer from '../shared/Footer'
import { Outlet } from 'react-router-dom'
import SubHeader from '../shared/SubHeader'

export default function Layout() {
  return (
    <>
    <SubHeader/>
    <Header/>
    <main >
      
      <Outlet />
      
    </main>
  </>
  )
}
