import React, { useState,useEffect} from 'react';
import { GetRoles } from '../../Service/Directionclub/DirectionclubApi';
import {getdirectionbyid} from '../../Service/Directionclub/DirectionclubApi';
import { useParams ,useNavigate} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function TableauDirectionById() {
   const [persons, setPersons] = useState([]);
   const [roles , setroles]=useState([])
   const { id } = useParams();
   const { REACT_APP_API_URL } = process.env;
   const url = REACT_APP_API_URL ;
   const idclub = decodeURIComponent(id);

  useEffect(() => {
    const fetchRoles = async()=>{
     const roleResp = await GetRoles();
     if(roleResp.status === 200){
        setroles(roleResp.data.data); 
     }else{
      toast.error("نحن آسفون، يبدو أن هناك مشكلة")
    }
     
     }  
     fetchRoles();  
   }, []);
   useEffect(() => {
    const fetchdirection = async()=>{

     const response = await getdirectionbyid(idclub);
     
     if(response.status === 200){
       
        setPersons(response.data.data); 
     }else{
      toast.error("نحن آسفون، يبدو أن هناك مشكلة")
    }
     
     }  
     fetchdirection();  
   }, []);
 
   const openPDFInNewTab = (pdfFileName) => {
    const FN = encodeURIComponent(pdfFileName);
    const pdfUrl = `${url}uploadfile/downloadfederations/${FN}`;
  
    const newTab = window.open(pdfUrl, '_blank');
  
    if (!newTab) {
      toast.error("منع فتح نافذة جديدة")

    }
  };


  return (
    
    <div style={{'backgroundColor':'white'}}>
 <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

    
         <header>
<div className="image-container">
 
<div className="info-container">

 <p><strong >قائمة  المسيرين     </strong></p>

 <p><strong> الموسم الرياضي {localStorage.getItem('saison')}</strong></p>
 </div>

</div>

</header>
         
      <table>
        <thead>
          <tr>
            <th>الاسم</th>
            <th>اللقب</th>
            <th>الجنسية</th>
            <th>رقم بطاقة الهوية</th>
            <th>تاريخ الاصدار</th>
            <th> مكان الولادة</th>
            <th>تاريخ الولادة</th>
            <th>الوظيفة</th>
            <th>رقم الهاتف</th>
            <th>البريد الإلكتروني</th>
           
          </tr>
        </thead>
        <tbody>
          {persons.map((person) => (
            <tr key={person.id}>
              <td>
            
                 { person.nom}
               
              </td>
              <td>
            
                 {person.prenom}
               
              </td>
              <td>
             
               {person.nationalite}   
               
              </td>
              <td>
              
                 { person.cin}
           
              </td>
              <td>
             {new Date(person.datecin).getFullYear()}-{(new Date(person.datecin).getMonth() + 1)
                    .toString()
                    .padStart(2, '0')}-{new Date(person.datecin).getDate().toString().padStart(2, '0')}`
               
              </td>
              <td>
                
                 {person.lieunaissance} 
               
              </td>
              <td>
            
                    {new Date(person.datenaissance).getFullYear()}-{(new Date(person.datenaissance).getMonth() + 1)
                        .toString()
                        .padStart(2, '0')}-{new Date(person.datenaissance).getDate().toString().padStart(2, '0')}`
                    
              </td>
              <td>
             
                {person.role} 
              
              </td>
              <td>
              
                { person.numTelephone} 
            
              </td>
              <td>
              { person.email}
                 
            
              </td>

          
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );
  
}
/*              <td ><a onClick={()=>{openPDFInNewTab(  `${ person.nom }_${ person.prenom }_${idclub}_directions`)}}> &#128065;</a></td>
*/