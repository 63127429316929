
import axios from "axios"


const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Authentification Web Service 
 * @returns 
 */

export async function uploadimage(imageName,data) {

    try {
        axios.defaults.headers.common['X-File-Name'] = imageName;
       const response = await axios.post(`${url}image/upload`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   
