import React, { useEffect, useState } from 'react'
import { getclub, updatesteps } from '../../Service/Inscription/ClubInscription/clubinscription';
import { getdirection } from '../../Service/Directionclub/DirectionclubApi';
import { getentraineur } from '../../Service/Entraineur/EntraineurApi';
import html2pdf from 'html2pdf.js';
import { getFederation } from '../../Service/Federation/FederationApi';
import {Getdiscipline} from '../../Service/Discipline/DisciplineApi'
import PdfHeader from '../../shared/PdfHeader';
import PdfFooter from '../../shared/PdfFooter';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Pdfinscription() {
    const { REACT_APP_API_URL } = process.env;
    const navigate = useNavigate()
    const url = REACT_APP_API_URL
    const [club , setclub]=useState([]); 
    const [direction , setdirection]=useState([]); 
    const [sousdirection , setsousdirection]=useState([]); 
    const [entraineurs , setEntraineur]=useState([]);
    const [federation , setfederation]=useState([]); 
    const  [specialite , setspecialite]=useState([])
    const [listeSpecialite , setListeSpecialite]=useState([])
 

    useEffect(() => {
        const fetchfederation = async()=>{
         const federationResp = await getFederation();
         if(federationResp.status === 200){
            setfederation(federationResp.data.data); 
          localStorage.setItem('saison',federationResp.data.data.saison)
         }else{
          toast.error("نحن آسفون، يبدو أن هناك مشكلة")
         }
         
         }  
         fetchfederation();  
       }, []);
        useEffect(() => {
            const fetchClubs = async()=>{
            const clubResp = await getclub();
            if(clubResp.status === 200){
              
                setclub(clubResp.data.data[0]); 
            }else{
              toast.error("نحن آسفون، يبدو أن هناك مشكلة")

            }
            
            }  
            fetchClubs();  
        }, []);

        useEffect(() => {
            const fetchDirection = async () => {
              try {
                const diretionResp = await getdirection();
                if (diretionResp.status === 200) {
                  const newDirection = [];
                  const newSousDirection = [];
          
                  diretionResp.data.data.forEach(element => {
                    if (element.role === " رئيس الفرع" || element.role === " عضو في الفرع") {
                      newSousDirection.push(element);
                    } else {
                      newDirection.push(element);
                    }
                  });
          
                  setdirection(newDirection);
                  setsousdirection(newSousDirection);
                } 
              } catch (error) {
                toast.error("نحن آسفون، يبدو أن هناك مشكلة")

              }
            };
            
            fetchDirection();
          }, []);

          useEffect(() => {
            const fetchentraineur = async()=>{
               const EntraineurResp = await getentraineur();
               if (EntraineurResp.status === 200) {
              const respdiscipline = await Getdiscipline();
             
            
              EntraineurResp.data.data.forEach(element => {
                //categorie ages 
          
                //sepecialites 
                const specialiteListe = []; 
                const categoriesAges = [];

                respdiscipline.data.data.forEach(item => {
                  if (element[item.libellerFR] !== null ) {

                    specialiteListe.push(item['LibellerAR']);
                    categoriesAges.push(element[`${[item.libellerFR]}_CA`])

                  }
                });
                element.specialites=specialiteListe.join(',');
                const  categoriesAgeslist = categoriesAges.filter(item => item !== '' && item !== null);
        
                  element.categories_age=categoriesAgeslist.join(',')
              });
              
            
              setEntraineur(EntraineurResp.data.data);
            }
            
            else{
              toast.error("نحن آسفون، يبدو أن هناك مشكلة")

            }            updatestep();

            
            }  
            fetchentraineur();  

        }, []);
       
          const updatestep = async()=>{
            const data = {step:2};
            if(club.stepInscription < data.step){
              
          
          const clubResp = await updatesteps(data);
          if(clubResp.status !== 200){
      
            toast.error("نحن آسفون، يبدو أن هناك مشكلة")

          
        }
          }  
     
        }
       const download=()=>{
    const element = document.getElementById('pdf-content');
              html2pdf()
                .set({
                  margin: 1,
                  filename: `مطلب إنخراط.pdf`,
                  image: { type: 'png', quality: 0.98 },
                  html2canvas: { useCORS: true }
                })
                .from(element)
                .save();
                updatestep();

       }
          
      
       const handleClick = () => {
   
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // You can use 'auto' or 'instant' instead of 'smooth' for different scrolling behavior
        });
        navigate('/Homeclub')
      };
      
  return (
    <div>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />


<div className="pdf-content" id="pdf-content">
<div>
  <PdfHeader nom=" مطلب انخراط و اعادة انخراط بالجامعة التونسية للجمباز" />
 
 
    <br />
    <div className="logo-containerpdf ">
      <strong>الشعار:</strong>
      <img
        src={`data:image/png;base64,${club.logo}`}
        alt="Club Logo"
        width="100"
        height="100"
      />
    </div>
    <div className='mr-5'>
    <p>
      <strong>الولاية:</strong>&nbsp;&nbsp;{club.gouvernorat}
    </p>
    <p>
      <strong>إسم الجمعية أو النادي :</strong>&nbsp;&nbsp;{club.nomClub}
    </p>
    <p>
      <strong>العنوان :</strong>&nbsp;&nbsp; {club.adresse}
    </p>
    <p>
      <strong>رقم التأشيرة :</strong>&nbsp;&nbsp;{club.Numvisa}
    </p>
    <p>
      <strong>تاريخ التأشيرة :</strong>&nbsp;&nbsp; {`${new Date(club.dateVisa).getFullYear()}-${(new Date(club.dateVisa).getMonth() + 1)
                    .toString()
                    .padStart(2, '0')}-${new Date(club.dateVisa).getDate().toString().padStart(2, '0')}`}
    </p>
    <p>
      <strong>الهاتف :</strong>&nbsp;&nbsp;{club.NmTelephone}
    </p>
    <p>
      <strong>الفاكس:</strong>&nbsp;&nbsp;{club.fax}
    </p>
    <p>
      <strong>البريد الإلكتروني :</strong>&nbsp;&nbsp;{club.Email}
    </p>
    <p>
      <strong>الاختصاصات:</strong>&nbsp;&nbsp;{club.specialite}
    </p>
  </div>
  </div>
  <header>
    <div className="image-container">
      <div className="info-container">
        <p>
          <strong>تركيبة الهيئة المديرة:</strong>
        </p>
      </div>
    </div>
  </header>
  <div className='mt-5 mb-5 mr-5'>
    {direction.map((item, index) => (
      <div key={index} className="row">
        
        <p className='col-6'>
          {item.role} :&nbsp;&nbsp; {item.nom} {item.prenom}
        </p>
        <p className='col-6'>
          الهاتف:&nbsp;&nbsp; {item.numTelephone}
        </p>
      </div>
    ))}
  </div>
  <header>
    <div className="image-container">
      
      <div className="info-container mb-3">
        <p>          <strong>تركيبة فرع الجمباز:</strong>

        </p>
      </div>
    </div>
  </header>

  <div>
    {sousdirection.map((item, index) => (
      <div key={index} className="team-member">
        <p>
          {item.role} :&nbsp;&nbsp; {item.nom} {item.prenom}
        </p>
        <p>
          الهاتف:&nbsp;&nbsp; {item.numTelephone}
        </p>
      </div>
    ))}
  </div>
  <header>
    <div className="image-container">
      <div className="info-container">
        <p>         
           <strong>  المدربون:</strong>

        </p>
      </div>
    </div>
  </header>
  <div>
      <table>
      <thead>
        <tr>
        <th>اسم المدرب</th>
          <th>الاختصاص</th>
          <th>الصنف العمري</th>
         
        </tr>
      </thead>
      <tbody>
      {entraineurs.map((item, index) => (
        <tr key={index}>
             <td>{item.nom}{'  '}{item.prenom}</td>
        <td>{item.specialites }</td>
        <td>{item.categories_age}</td>
     
      </tr>       
      
      ))}
      </tbody>
    </table>
      </div>

    <div className="row mt-5 mb-5">
      <div className="col-4">
        <p>التاريخ</p>
      </div>
      <div className="col-4">
        <p>ختم الجمعية</p>
      </div>
      <div className="col-4">
        <p>امضاء رئيس او كاتب عام الجمعية</p>
      </div>
    </div>


  <PdfFooter />
</div>
<div className='row   mt-3 mr-5'>
<div className='col-9'>

<button onClick={download}>تحميل</button>
<strong style={{color :'red'}}>يجب طباعة هذه الوثيقة و ختمها ثم تحميلها علي المنصة    </strong>
</div>

<button className='col-2 ' onClick={handleClick}>التالي</button>

</div>

    </div>
  )
}

        

/*<p>الشعار: <img src={url+"uploads/"+club.logo} alt={club.logo} id="logo-image" style={ { width: '100px',    height: 'auto',  }}/></p> */

/*****************
 * 
 * 
 * 
 * 
 * <header className="headerpdf">
    <div className="info-containerpdf">
      <div className="arabic-infopdf">
        <p>{federation.nomAr}</p>
        <p>{federation.adresseAr}</p>
        <p>الهاتف / الفاكس: {federation.telfax}</p>
        <p>البريد إلكتروني: {federation.email}</p>
      </div>
      <div className="image-containerpdf">
        <img src="/images/logo_FTG.jpg" alt="Image" width="150" height="150" id="logo-image" />
      </div>
      <div className="french-infopdf">
        <p>{federation.nomFr}</p>
        <p>{federation.adresseFr}</p>
        <p>Tél./Fax : {federation.telfax}</p>
        <p>Email : {federation.email}</p>
      </div>
    </div>
  </header>
 */