import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Routespublic , Routesprivate,RoutesFedreation ,PublicRoute} from './routes/router';
import Layout from './Layout/layout';
import FederationLayout from './Layout/FederationLayout';
import AcceuilLayout from './Layout/AcceuilLayout';
import Certificats from './Page/Certificats/Certificats';
function App() {
  const conx = localStorage.getItem("idunique");
  const IDrole = localStorage.getItem('IDrole') ;
  return (
    <BrowserRouter>
      <Routes>
      {conx  && (
      <Route path="/" element={<Layout />}>
      {Routesprivate.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
        </Route>
      )}
      
      <Route path="/home" element={<Layout />}>
      {Routespublic.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
        </Route>
    <Route path="" element={<AcceuilLayout/> }>
      {PublicRoute.map((route)=>(
              <Route key={route.path} path={route.path} element={route.element} />

      ))}
    </Route>
        
      
      { IDrole? (
          <Route path="" element={<FederationLayout />}>
      {RoutesFedreation.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
        </Route>
      ):null}
      <Route path="">
      <Route key='/Certificats/:data' path='/Certificats/:data' element={<Certificats />} />

      </Route>
               
      </Routes>
    </BrowserRouter>
  );
  

}

export default App;
/* {conx ? (
          <>
            <Route path="/" element={<Layout />} />
            {Routesprivate.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </>
        ) : (
          <>
            <Route path="*" element={<Layout />} />
            {Routespublic.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </>
        )}*/