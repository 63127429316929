import React, { useState,useEffect} from 'react';
import { useParams ,useNavigate} from 'react-router-dom';
import {Getdiscipline} from '../../Service/Discipline/DisciplineApi'
import { setdevalideAthlete, setrevalideAthlete, setvalideAthelet } from '../../Service/Athlete/AthleteApi';
import { getatheletebyid } from '../../Service/Athlete/AthleteApi';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function TableauAtheletteById() {
  const navigate = useNavigate();
  const { id } = useParams();
    const [showPopup, setShowPopup] = useState(false);
    const [item, setSelectedItem] = useState('');
    const [newlisteObject , setnewlisteObject]=useState([]);
    const { REACT_APP_API_URL } = process.env;
    const url = REACT_APP_API_URL
    

    useEffect(() => {
      const fetchatheletes = async () => {
        const idclub = decodeURIComponent(id);
    
        const response = await getatheletebyid(idclub);
        if (response.status === 200) {
          const respdiscipline = await Getdiscipline();
       
          
          // Create a Set to store unique objects
          const uniqueObjects = new Set();
    
          response.data.data.forEach((element) => {
            respdiscipline.data.data.forEach((item) => {
              if (element[item.libellerFR] !== null) {
                let object = {
                  specialites: item.LibellerAR,
                  specialitesFR: item.libellerFR,

                  categories_age: element[`${item.libellerFR}_CA`],
                  numLicences: element[`${item.libellerFR}`],
                  element, // Include the entire 'element' object
                };
    
                // Add the unique object to the Set
                uniqueObjects.add(JSON.stringify(object));
              }
            });
          });
    
          // Convert the Set back to an array
          const uniqueObjectArray = Array.from(uniqueObjects).map((jsonObject) =>
            JSON.parse(jsonObject)
          );
    
          setnewlisteObject(uniqueObjectArray);
        } else {
          toast.error("نحن آسفون، يبدو أن هناك مشكلة")
        }
      };
    
      fetchatheletes();
    }, []);
    
    const openPDFInNewTab = (item,type) => {
      const idclub = decodeURIComponent(id);
  
      const FN = encodeURIComponent(`${idclub}_${type}_${item.element.idunique}`);
      const pdfUrl = `${url}uploadfile/downloadfederations/${FN}`;
    
      const newTab = window.open(pdfUrl, '_blank');
    
      if (!newTab) {
        toast.error('منع فتح نافذة جديدة');
      }
    };
    const setvalide= async(item)=>{
     const resp = await setvalideAthelet(`${item.specialitesFR}_valide`, item.element.idunique); 
     if(resp.status === 200){
      window.location.reload() 
     }
    }
    // Function to handle row click and show the popup with the selected image
    const handleRowClick = (item) => {
      setSelectedItem(item);
      setShowPopup(true);
    };
    const devalider = async (item) => {

      const resp = await setdevalideAthlete(`${item.specialitesFR}_valide`, item.element.idunique);
      if (resp.status === 200) {
  
        window.location.reload()
      }
    }
  
    const revalider = async (item) => {
  
      const resp = await setrevalideAthlete(`${item.specialitesFR}_valide`, item.element.idunique);
      if (resp.status === 200) {
  
        window.location.reload()
      }
    }
   return (
     <div style={{'backgroundColor':'white'}}>
           <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

          <header>
 <div className="image-container">
  
 <div className="info-container">
 
  <p><strong >قائمة  الرياضيين     </strong></p>
 
  <p><strong> الموسم الرياضي {localStorage.getItem('saison')}</strong></p>
  </div>
 
 </div>
 
 </header>
          
       <table>
         <thead>
           <tr>
             <th>الاسم</th>
             <th>اللقب</th>
             <th>تاريخ الولادة</th>
             <th>الفئة العمرية</th>
             <th>الاختصاص</th>
             <th>رقم  الاجازة </th>
          

           </tr>
         </thead>
         <tbody>
           {newlisteObject.map((person) => (
             <tr key={person.id}>
               <td onClick={() => handleRowClick(person)}>
               
                  {person.element.nom} 
              
               </td>
               <td>
              
                   {person.element.prenom}
               
               </td>
             
         
               <td>
             
                      {new Date(person.element.datenaissance).getFullYear()}-{(new Date(person.element.datenaissance).getMonth() + 1)
                    .toString()
                    .padStart(2, '0')}-{new Date(person.element.datenaissance).getDate().toString().padStart(2, '0')}`
              
              </td>
               <td>
         
                   {person.categories_age}
             
               </td>
          
               <td>
               
                  { person.specialites}
               
               </td>
               <td> { person.numLicences}</td>
               
               <td  ><a style={{ color: 'blue', textDecoration: 'underline' } } onClick={() => openPDFInNewTab(person.element.actnaissance	)}>ActNaissance</a></td>
              <td   ><a style={{ color: 'blue', textDecoration: 'underline' } } onClick={() => openPDFInNewTab(person.element.PermissionParentale)}> PermissionParentale </a> </td>
              <td   ><a style={{ color: 'blue', textDecoration: 'underline' } } onClick={() => openPDFInNewTab(person.element.certificatmedicale)}> certificatmedicale </a> </td>

              <td>{person.element[`${person.specialitesFR}_valide`]===0  ? (<div> 
                <button onClick={()=>{setvalide(person)}}> valider </button>
              </div>):
                person.element[`${person.specialitesFR}_valide`] === 1 ? (
                  <button onClick={() => { devalider(person) }}> إبطال صالحيته
                  </button>
                ) :
                <button onClick={() => { revalider(person) }}> valider
                </button>

              }</td>
            </tr>
          ))}
           
         </tbody>
       </table>
       {showPopup && (
        <div className="popupinfo">
          <div className="popup-contentinfo">
            <span className="close-popup" onClick={() => setShowPopup(false)}>
              &times;
            </span>
            <img src={`data:image/png;base64,${item.element.image1}`} style={{ width: '200px', height: '201px' }} />
          </div>
        </div>
      )}
     </div>
   );
   
 }
 