import axios from "axios"


const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Get pub Acceuil
 * @returns 
 */

export async function GetPubAcceuil() {

    try {
        
        const response = await axios.get(`${url}accueil/getpubs`)
        return response;

    }catch(error){
        return error?.response
    }
  }   