import React, { useState, useRef } from 'react';
import jsQR from 'jsqr';

export default function QRCodeScanner() {
  const [qrCodeData, setQrCodeData] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    try {
      const imageData = new ImageData(await file.arrayBuffer(), file.width, file.height);
      const code = jsQR(imageData.data, imageData.width, imageData.height);

      if (code) {
        setQrCodeData(code.data);
      } else {
        setQrCodeData('Aucun QR code trouvé.');
      }
    } catch (error) {
      console.error('Erreur lors de la lecture du QR code :', error);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <button onClick={() => fileInputRef.current.click()}>Sélectionner une image</button>
      {qrCodeData && (
        <div>
          <h2>Données du QR Code</h2>
          <p>{qrCodeData}</p>
        </div>
      )}
    </div>
  );
}
