import React ,{useState,useEffect}from 'react'
import { Nav, Tab, Row, Col, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { createrole, createuser, getroles } from '../../Service/Federation/FederationApi';
import { toast, ToastContainer } from 'react-toastify';
import { useForm  } from "react-hook-form";
import 'bootstrap/dist/css/bootstrap.min.css'; 
export default function AjoutRoles({showModal ,setshowModal}) {


    const { register, handleSubmit, reset } = useForm();
    const onSubmit = async (data) => {
        const response = await createrole(data)
       if(response && response.status === 201){
        toast.success(' تم إدراج المهنة        ')
        data ={}
        setshowModal(false)
    }else{
        toast.error('حدث خطأ أثناء حفظ البيانات');

    }
          };
  return (
    
    <div>
    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />
  
              <Modal show={showModal} onHide={()=>setshowModal(false)}>
                  <Modal.Header closeButton>
                      <Modal.Title>إضافة منصب</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <form onSubmit={handleSubmit(onSubmit)} >
  
  
                          <div className="form-group">
                              <label htmlFor="libeller">  اسم المهنة<span className="required"></span></label>
                              <input
                                  type="text"
                                  required
                                  {...register("libeller")}
                              />
                          </div>
                      
                          
                          <button type='submit'> حفظ</button>
                      </form>            
                      </Modal.Body>
              </Modal>
          </div>
  )
}
