import axios from "axios"


const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Authentification Web Service 
 * @returns 
 */

export async function insertathelete(data) {

    try {
        const response = await axios.post(`${url}athelete/insertAthlete`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   

  export async function getathelete(){

    try {
        const idclub = localStorage.getItem('idunique');
        const response = await axios.get(`${url}athelete/getAthlete/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  }  
  export async function getatheletebyid(idclub){

    try {
      
        const response = await axios.get(`${url}athelete/getAthlete/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  }    
  export async function updateAthlete (data) {

    try {
        
        const response = await axios.put(`${url}athelete/updateAthlete`,data)
        console.log(response)
        return response;

    }catch(error){
        return error?.response
    }
  } 

  export async function deleteAthlete(id){

    try {
        const response = await axios.delete(`${url}athelete/delete/${id}`)
        return response;

    }catch(error){
        return error?.response
    }
  }  

  export async function getcategory(datenaissance , specialite){
    try {
        const saison = localStorage.getItem('saison');
        const response = await axios.get(`${url}athelete/getcategory/${specialite}/${datenaissance}/${saison}`)
        return response;

    }catch(error){
        return error?.response
    }
  }

  export async function getAllAthelets(){
    try {
        const saison = localStorage.getItem('saison');
        const response = await axios.get(`${url}athelete/getall/${saison}`)
        return response;

    }catch(error){
        return error?.response
    }
  }

    
  export async function setvalideAthelet(type ,id){
    try {
      
        const response = await axios.put(`${url}athelete/valide/${type}/${id}`)
        return response;

    }catch(error){
        return error?.response
    }
  }
  export async function setdevalideAthlete(type , id){
    try {
      
        const response = await axios.put(`${url}athelete/devalide/${type}/${id}`)
        return response;

    }catch(error){
        return error?.response
    }
  }

  export async function setrevalideAthlete(type , id){
    try {
      
        const response = await axios.put(`${url}athelete/revalide/${type}/${id}`)
        return response;

    }catch(error){
        return error?.response
    }
  }