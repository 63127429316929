export const listeGouvernorat = [
                 "تونس",
                "أريانة",
                "منوبة",
                "بن عروس",
                "نابل",
                "زغوان",
                "باجة",
                "جندوبة",
                "سليانة",
                "القصرين",
                "سيدي بوزيد",
                "القيروان",
                "المنستير",
                "صفاقس",
                "قفصة",
                "قبلي",
                "القفة",
                "توزر",
                "مدنين",
                "بنزرت",
]