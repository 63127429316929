import React from 'react'

export default function PdfHeader({nom}) {
  return (

   <div className='subheader row'>
   <div className='col-4'>
   <img src="/images/logo_FTG.png" alt="Image" width="150" height="150" id="logo-image" />

   </div>
 <div className='col-6'>
 <p >
   <strong >{nom} </strong>
 </p>
 <p  >
   <strong>الموسم الرياضي {localStorage.getItem('saison')}</strong>
 </p>
 </div>



</div>
)
}
