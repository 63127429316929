import React, { useRef } from 'react';
import html2pdf from 'html2pdf.js';
import Pdfinscription from '../PdfInscription/Pdfinscription';
import Header from '../../shared/Header';
import FormulaireLogin from '../Login/FormulaireLogin'
import { useNavigate } from "react-router-dom";
import Footer from '../../shared/Footer';

export default function Home() {

  const navigate = useNavigate();

  const downloadPDF = () => {
    const element = document.getElementById('pdf-content');
  
    html2pdf()
      .set({
        margin: 1,
        filename: `مطلب إنخراط.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { useCORS: true }
      })
      .from(element)
      
      .save();
  };

  return (
    <div>

      <div>
      <div className="containerdiv">
      <div className="right-panel">
        <p>إذا لم يكن لديك حساب، قم بالتسجيل</p>
        <button className='submit-btn' onClick={()=>{navigate('/home/inscription')}}>استمارة التسجيل</button>
      </div>
      <hr className="divider" />
      <div className="left-panel">
       <FormulaireLogin/>
      </div>
     
    </div>
      </div>
    </div>
  );
}
