import React ,{useEffect,useState} from 'react'
import { getFederation } from '../Service/Federation/FederationApi';
import { useNavigate } from "react-router-dom";

export default function HeaderAcceuil() {
    const [federation , setfederation]=useState([]); 
    const navigate = useNavigate();
    const currentPath = window.location.pathname; 

    useEffect(() => {
        const fetchfederation = async()=>{
         const federationResp = await getFederation();
         if(federationResp.status === 200){
            setfederation(federationResp.data.data); 
            localStorage.setItem('saison',federationResp.data.data.saison)
         }else{
           alert("no federation found")
         }
         
         }  
         fetchfederation();  
       }, []);
  return (
    <div >
        
    <div>
    <div className='subheader'>
    {federation.nomAr}&nbsp;&nbsp;
    
    البريد إلكتروني  : {federation.email}
   &nbsp;&nbsp;
    
    الهاتف   : {federation.telfax}
    
    <ul style={{'marginLeft':'200px'}}>
       <li> <button className="home" onClick={()=>{navigate('/home/loginadmin')}}>
 ➡️ فضاء الإداري
   </button></li>
   <li> <button className="home" onClick={()=>{navigate('/home')}}>
 ➡️ فضاء الأندية
   </button></li>
   <li> <button className="home" onClick={()=>{navigate('/')}}>
 ➡️  الصفحة الرئيسية{/* Unicode for a logout icon */}
   </button></li> 
     </ul>
     </div>
    <nav className="navbar">

        <ul className="menu-list">
        <li style={currentPath === '/Accueil/AllClubs' ? { color: 'red', textDecoration: 'underline' } : null} onClick={() => { navigate('/AllClubs'); }}>الأندية</li>
          <li style={currentPath === '/Accueil/AllEntraineurs' ? { color: 'red', textDecoration: 'underline' } : null} onClick={() => { navigate('/AllEntraineurs'); }}>المدربين</li>
          <li style={currentPath === '/Accueil/AllAtheletes' ? { color: 'red', textDecoration: 'underline' } : null} onClick={() => { navigate('/AllAtheletes'); }}>الرياضيين</li>
        </ul>
      </nav>
    </div>

        </div>
  )
}
