
import axios from "axios"


const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Authentification Web Service 
 * @returns 
 */

export async function CreateClub(idclub,data) {

    try {
        axios.defaults.headers.common['x-club-name'] = idclub;
        const response = await axios.post(`${url}Club/create`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   

export async function insertpassword(data){

    try {
        console.log(data);
        const response = await axios.post(`${url}Club/insertpassword`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   

  
export async function getclub(){

    try {
        const idclub = localStorage.getItem('idunique');
        const response = await axios.get(`${url}Club/getclub/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  }   

  export async function getclubbyid(idclub){

    try {
        const response = await axios.get(`${url}Club/getclub/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  } 
  export async function updatesteps(data){

    try {
        console.log(data);
        const response = await axios.put(`${url}Club/updateste`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   

    
export async function getinfoclub(){

    try {
        const idclub = localStorage.getItem('idunique');
        const response = await axios.get(`${url}Club/getinfoclub/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  }   
  export async function getcount(){

    try {
        const idclub = localStorage.getItem('idunique');
        const response = await axios.get(`${url}Club/getcount/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  }   
  export async function updateClub (data) {

    try {
        
        const response = await axios.put(`${url}Club/updateclub`,data)
        return response;

    }catch(error){
        return error?.response
    }
  } 
