import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {Getdiscipline} from '../../Service/Discipline/DisciplineApi'
import html2pdf from 'html2pdf.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function QRCodeGenerator() {
  const [qrCodeData, setQRCodeData] = useState('');
  //const [club, setClub] = useState([]);
  const [descipline, setDescipline] = useState([]);
  const [listeLicences, setListeLicences] = useState([]);
  const navigate = useNavigate();
  const { data } = useParams();

  const parsedData = JSON.parse(decodeURIComponent(data));



  useEffect(() => {
    const fetchLicences = async () => {
      const certificateUrl = `http://localhost:3000/Certificats/${encodeURIComponent(JSON.stringify(parsedData))}`;
      parsedData.certificateUrl = certificateUrl;
      const respdiscipline = await Getdiscipline();
  
      const updatedListeLicences = [];
  
      // Use Promise.all to handle asynchronous operations inside the loop
      await Promise.all(respdiscipline.data.data.map(async element => {
        if (parsedData[element.libellerFR] !== null) {
          const updatedParsedData = { ...parsedData };
          updatedParsedData.numLicence = parsedData[element.libellerFR];
          updatedParsedData.specFr = parsedData[`${element.libellerFR}_valide`];

          updatedParsedData.spec = element['LibellerAR'];
          updatedParsedData.cat_age = parsedData[`${[element.libellerFR]}_CA`];
          updatedParsedData.QRdata = {
            nom: parsedData.nom,
            prenom: parsedData.prenom,
            id:parsedData.id,
            idClub:parsedData.idClub,
            numlicence: parsedData[element.libellerFR],
          };
  
          updatedListeLicences.push(updatedParsedData);
        }
      }));
  
      setListeLicences(updatedListeLicences);
    };
  
    fetchLicences();
  }, [parsedData]);
  

  const handleButtonClick = item => {
    const newTab = window.open(`${process.env.REACT_APP_API_FRONT_URL}Certificats/${encodeURIComponent(JSON.stringify(item.QRdata))}`, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      toast.error('منع فتح نافذة جديدة.');
    }
  };

  const download = item => {
    const element = document.getElementById(`pdf-content${item.numLicence}`);
    html2pdf()
      .set({
        margin: 1,
        filename: `licence${parsedData.nom}_${parsedData.prenom}.pdf`,
        image: { type: 'png', quality: 0.98 },
        html2canvas: { useCORS: true },
      })
      .from(element)
      .save();
  };

  return (
    <div>
- <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={true} pauseOnFocusLoss draggable pauseOnHover />

      {listeLicences.map(item => (
        <div key={item.numLicence}>
          <button onClick={() => download(item)}>تحميل</button>
          <div>
            <div className='licence'>
              <div className="certificate" id={`pdf-content${item.numLicence}`}>
                {/* Insert the rest of the certificate content here */}
                <div className='header'>
                  <div className="info-container">
                    <div className="arabic-info"></div>
                    <div className="image-container">
                      <h1>{}</h1>
                      <h1> {item.specFr === 0  ?'وصل '  : null }اجازة مدرب {item.specFr !== 0  ?'رقمية '  : null }</h1>

                      <p><strong> الموسم الرياضي {localStorage.getItem('saison')}</strong></p>
                    </div>
                    <div className="french-info">
                      <img src="/images/logo_FTG.png" alt="Image" width="150" height="150" id="logo-image" />
                    </div>
                  </div>
                </div>
                <div className="content">
                  <img src={`data:image/png;base64,${item.image1}`} style={{ width: '200px', height: '201px' }} />
                  <h2>{item.numLicence}</h2>
                  <p><strong>إسم واللقب </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.nom} &nbsp;&nbsp; {item.prenom}</p>
                  <p> <strong> رقم بطاقة الهوية </strong>   :  &nbsp;&nbsp; &nbsp;&nbsp;{parsedData.cin}  </p>

                  <p><strong>تاريخ الولادة </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{new Date(item.datenaissance).getFullYear()}-{(new Date(item.datenaissance).getMonth() + 1)
                    .toString()
                    .padStart(2, '0')}-{new Date(item.datenaissance).getDate().toString().padStart(2, '0')} </p>
                  <p><strong> مكان الولادة </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.lieunaissance}</p>
                  <p><strong>  الجنسية </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.nationalite}</p>
                  <p><strong>  إسم الجمعية أو النادي </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{localStorage.getItem('clubName')}</p>
                  <p><strong>الاختصاص </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.spec}</p>
              
                  <p><strong>الفئة العمرية </strong> : &nbsp;&nbsp; &nbsp;&nbsp;{item.cat_age}</p>
                </div>
                 
                { item.specFr > 0 ?(
                <div className="qr-code">
                  <QRCode value={item.QRdata}    onScan={() => handleButtonClick(item)} size={128} />
                  <button onClick={() => handleButtonClick(item)}></button>
                </div>):null}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}