import axios from "axios"


const { REACT_APP_API_URL } = process.env;

const url = REACT_APP_API_URL

/**
 * Authentification Web Service 
 * @returns 
 */

export async function GetRoles() {

    try {
        
        const response = await axios.get(`${url}direction/getroles`)
        return response;

    }catch(error){
        return error?.response
    }
  }   


/**
 * Authentification Web Service 
 * @returns 
 */

export async function insertDirection (data) {

    try {
        
        const response = await axios.post(`${url}direction/insertdirection`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   

  export async function updateDirection (data) {

    try {
        
        const response = await axios.put(`${url}direction/updatedirection`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   

  export async function getdirection(){

    try {
        const idclub = localStorage.getItem('idunique');
        const response = await axios.get(`${url}direction/getdirection/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  }   

  export async function getdirectionbyid(idclub){

    try {
        
        const response = await axios.get(`${url}direction/getdirection/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  }  



  export async function getdirectionbyclub(){

    try {
        const idclub = localStorage.getItem('idunique');
        const response = await axios.get(`${url}direction/getdirectionbyclub/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  } 
  export async function getcountdirection(){

    try {
        const idclub = localStorage.getItem('idunique');
        const response = await axios.get(`${url}direction/countdirection/${idclub}`)
        return response;

    }catch(error){
        return error?.response
    }
  }   

  export async function deletedirection(id){

    try {
        const response = await axios.delete(`${url}direction/delete/${id}`)
        return response;

    }catch(error){
        return error?.response
    }
  }   
  export async function create (data) {

    try {

        const response = await axios.post(`${url}direction/create`,data)
        return response;

    }catch(error){
        return error?.response
    }
  }   

